/**
 * List of events used by booking-gate widget
 */
export const bookingGate = {
  BOOKING_GATE_PARTICIPANTS_ALLOCATED: 'bookingGate.ParticipantsAllocated',
  BOOKING_GATE_CLOSE_BASKET_BUTTON_CLICKED: 'bookingGate.CloseBasketButtonClicked',
  BOOKING_GATE_FLIGHT_CHANGED: 'bookingGate.FlightChanged',
  BOOKING_GATE_BUS_CHANGED: 'bookingGate.BusChanged',
  BOOKING_GATE_BOOKING_SUBMITTED: 'bookingGate.BookingSubmitted',
  BOOKING_GATE_ERRORS_THROWN: 'bookingGate.ErrorsThrown',
  BOOKING_GATE_PRICE_CELL_CLICKED: 'bookingGate.PriceCellClicked',
  BOOKING_GATE_PRICE_TABLE_VIEWED: 'bookingGate.PriceTableViewed',
  BOOKING_GATE_OPEN_JAW_MODAL_OPEN: 'bookingGate.OpenJawModalOpened',
  BOOKING_GATE_OPEN_JAW_MODAL_SUBMIT: 'bookingGate.OpenJawModalSubmitted',
  BOOKING_GATE_OPEN_JAW_MODAL_CLOSE: 'bookingGate.OpenJawModalClosed',
  BOOKING_GATE_OPEN_JAW_SWITCH: 'bookingGate.OpenJawSwitch',
  BOOKING_GATE_OPEN_START_BOOKING_ERROR_MODAL: 'bookingGate.StartBookingErrorModelOpened'
}
